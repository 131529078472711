import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'
import { ORDEN_TRABAJO } from '@/utils/consts'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectZona', method: 'zona.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTactuacion', method: 'tactuacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTsistema', method: 'tsistema.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTmotivoBloqueoOt', method: 'tmotivoBloqueoOt.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectGrupoCliente', method: 'grupoCliente.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectComercial', method: 'comercial.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTecnico', method: 'tecnico.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectOrdenTrabajo (Vue, filter, search, sorter, page, estados, soloMisOT, idtecnico, idsistema, mapBounds, pageSize, idcliente) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addIn('idest_orden_trabajo', estados)
    if (estados.length === 1 && estados[0] === ORDEN_TRABAJO.estados.pendiente) {
      apiFilter.addNOP('sin_asignar')
    }
    if (idsistema) {
      apiFilter.addExact('idsistema', idsistema)
    }
    if (idcliente) {
      apiFilter.addExact('idcliente', idcliente)
    }
    if (soloMisOT) {
      apiFilter.addExact('idtecnico', idtecnico)
    }
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('cliente_nombre', search)
        .addILike('sistema_descripcion', search)
      if (!isNaN(search)) {
        apiFilterSearch
          .addExact('norden_trabajo', search)
      }
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.zona.value) {
      apiFilter.addExact(filter.zona.field, filter.zona.value)
    }
    if (filter.tactuacion.value) {
      apiFilter.addExact(filter.tactuacion.field, filter.tactuacion.value)
    }
    if (filter.tsistema.value) {
      apiFilter.addExact(filter.tsistema.field, filter.tsistema.value)
    }
    if (filter.grupoCliente.value) {
      apiFilter.addExact(filter.grupoCliente.field, filter.grupoCliente.value)
    }
    if (filter.tmotivoBloqueoOt.value) {
      apiFilter.addExact(filter.tmotivoBloqueoOt.field, filter.tmotivoBloqueoOt.value)
    }
    if (filter.conFfijada.value !== null) {
      apiFilter.addExact(filter.conFfijada.field, filter.conFfijada.value)
    }
    if (filter.ffijada.value) {
      apiFilter
        .addGTE(filter.ffijada.field, toDate(filter.ffijada.value))
        .addLTE(filter.ffijada.field, endOfDay(toDate(filter.ffijada.value)))
    }
    if (filter.ffinparte.value) {
      apiFilter
        .addGTE(filter.ffinparte.field, toDate(filter.ffinparte.value))
        .addLTE(filter.ffinparte.field, endOfDay(toDate(filter.ffinparte.value)))
    }
    if (filter.pendienteDevolucion.value !== null) {
      apiFilter.addExact(filter.pendienteDevolucion.field, filter.pendienteDevolucion.value)
    }
    if (filter.comercial.value !== null) {
      apiFilter.addExact(filter.comercial.field, filter.comercial.value)
    }
    if (filter.tecnico.value !== null) {
      apiFilter.addExact(filter.tecnico.field, filter.tecnico.value)
    }
    if (filter.sistemaDireccion.value) {
      apiFilter.addILike(filter.sistemaDireccion.field, filter.sistemaDireccion.value)
    }
    if (filter.soyResponsable.value) {
      apiFilter.addExact(filter.soyResponsable.field, idtecnico)
    }
    if (mapBounds) {
      apiFilter.addGT('latitud', mapBounds.swLat)
      apiFilter.addLT('latitud', mapBounds.neLat)
      apiFilter.addGT('longitud', mapBounds.swLng)
      apiFilter.addLT('longitud', mapBounds.neLng)
    }
    const resp = await Vue.$api.call('ordenTrabajo.selectList', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectOrdenTrabajoRows (Vue, pks, estados, soloMisOT, idtecnico) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idorden_trabajo', pks)
      .addIn('idest_orden_trabajo', estados)
    if (estados.length === 1 && estados[0] === ORDEN_TRABAJO.estados.pendiente) {
      apiFilter.addNOP('sin_asignar')
    }
    if (soloMisOT) {
      apiFilter.addExact('idtecnico', idtecnico)
    }
    const resp = await Vue.$api.call('ordenTrabajo.selectList', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
